import React, { useContext, useEffect, useState } from "react";
import PrimaryButton from "../../components/form/PrimaryButton";
import { ReactComponent as Lock } from "../../assets/lock-icon.svg";
import { ReactComponent as Envelope } from "../../assets/envelope-icon.svg";
import { ReactComponent as Person } from "../../assets/person.svg";
import TextField from "../../components/form/TextField";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import CustomCheckBox from "../../components/form/CustomCheckBox";

// firebase authentication
import { signInWithPopup } from "firebase/auth";
import { auth, googleProvider } from "../../utils/firebase";
import ModalContext from "../../contexts/ModalContext";

const Register = () => {
  // modal context
  const { setAlertProps, setIsLoginModalOpen, setIsRegisterModalOpen } =
    useContext(ModalContext);
  const [isFormValid, setIsFormValid] = useState(false);
  const [inputValues, setInputValues] = useState({
    username: "",
    email: "",
    password: "",
    terms: false,
  });

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (
      inputValues.email &&
      inputValues.password &&
      inputValues.terms &&
      inputValues.username
    ) {
      setIsFormValid(false);
    } else {
      setIsFormValid(true);
    }
  }, [inputValues]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    register();
  };

  // handle google authentication
  const handleGoggleAuth = () => {
    signInWithPopup(auth, googleProvider)
      .then((res) => {
        setErrorMessage("");

        registerWithGoogle(res._tokenResponse.oauthAccessToken);
      })
      .catch((err) => {
        setErrorMessage("Something Went Wrong");
        setAlertProps({
          type: "fail",
          title: "Error",
          subtitle: "Something went wrong",
        });
        setTimeout(() => {
          setAlertProps({
            type: "",
            title: "",
            subtitle: null,
          });
        }, 5000);
      });
  };

  // function to register new user with email and password
  const { mutate: register, isLoading: loadingEmailAuth } = useMutation(
    () => {
      const payload = {
        username: inputValues.username,
        email: inputValues.email,
        password: inputValues.password,
      };
      return axios.post(
        `${process.env.REACT_APP_BASE_URI}/auth/signup`,
        payload
      );
    },
    {
      onSuccess: (response) => {
        setAlertProps({
          type: "success",
          title: "Success",
          subtitle: response?.data?.message,
        });
        setTimeout(() => {
          setAlertProps({
            type: "",
            title: "",
            subtitle: null,
          });
          setIsRegisterModalOpen(false);
          setIsLoginModalOpen(true);
        }, 5000);

        // alert(response?.data?.message);
      },
      onError: (error) => {
        // setIsRegisterModalOpen(false);
        // setIsAlertModalOpen(true);
        setErrorMessage(error?.response?.data?.message || "Error");
        setAlertProps({
          type: "fail",
          title: "Error",
          subtitle: error?.response?.data?.message,
        });
        // alert(error?.response?.data?.message);
        setTimeout(() => {
          setAlertProps({
            type: "",
            title: "",
            subtitle: null,
          });
        }, 5000);
        // alert(error?.response?.data?.message);
      },
      onMutate: () => {},
      onSettled: () => {},
    }
  );
  // function to register new user with google
  const { mutate: registerWithGoogle, isLoading: loadingGoogleAuth } =
    useMutation(
      (googleToken) => {
        const payload = {
          accesstoken: googleToken,
        };
        return axios.post(
          `${process.env.REACT_APP_BASE_URI}/auth/googlesignup`,
          payload
        );
      },
      {
        onSuccess: (response) => {
          setAlertProps({
            type: "success",
            title: "Success",
            subtitle: response?.data?.message,
          });
          setTimeout(() => {
            setAlertProps({
              type: "",
              title: "",
              subtitle: null,
            });
            setIsRegisterModalOpen(false);
            setIsLoginModalOpen(true);
          }, 5000);

          // alert(response?.data?.message);
        },
        onError: (error) => {
          //   setIsRegisterModalOpen(false);
          // setIsAlertModalOpen(true);
          setErrorMessage(error?.response?.data?.message || "Error");
          setAlertProps({
            type: "fail",
            title: "Error",
            subtitle: error?.response?.data?.message,
          });
          // alert(error?.response?.data?.message);
          setTimeout(() => {
            setAlertProps({
              type: "",
              title: "",
              subtitle: null,
            });
          }, 5000);
        },
        onMutate: () => {},
        onSettled: () => {},
      }
    );

  return (
    <>
      <div className="bg-[#F2F2F2] relative px-5 py-7 md:rounded-[36px] md:w-[650px] w-full">
        <h1 className="text-2xl mt-4 text-center text-[#282B30] font-bold mb-1 md:text-3xl">
          Create New Account
        </h1>
        <p
          className="flex text-primary items-center gap-4 absolute cursor-pointer top-2 right-6"
          onClick={() => {
            setIsRegisterModalOpen(false);
          }}
        >
          Back
          <BsFillArrowRightCircleFill className="text-xl" />
        </p>
        <h2 className="text-center text-[#282B30] mb-12">
          Sign up to continue
        </h2>
        <form className="md:w-[400px] mx-auto">
          {errorMessage !== "" && (
            <div className="border-red-500 border-2 mb-9 rounded-full w-full bg-red-200 p-3">
              <p className="text-center text-red-500 font-semibold">
                {errorMessage}
              </p>
            </div>
          )}

          <div>{/* error message space */}</div>
          <div className="w-full h-14 mb-9">
            <TextField
              name="username"
              type="text"
              placeholder="Enter username"
              handleInputChange={handleInputChange}
              value={inputValues.username}
              icon={<Person />}
            />
          </div>
          <div className="w-full h-14 mb-9">
            <TextField
              name="email"
              type="text"
              placeholder="Enter email address"
              handleInputChange={handleInputChange}
              value={inputValues.email}
              icon={<Envelope />}
            />
          </div>
          <div className="w-full h-14 mb-6">
            <TextField
              name="password"
              type="password"
              placeholder="Enter password"
              handleInputChange={handleInputChange}
              value={inputValues.password}
              icon={<Lock />}
            />
          </div>

          {/* <div className="my-5 text-[#282B30] ">
            <ul>
              <p className="text-sm mb-3">Password Requirements: </p>
              <li className="text-[13px]">At least 8 characters long</li>
              <li className="text-[13px]">
                Contains at least one uppercase letter
              </li>
              <li className="text-[13px]">
                Contains at least one lowercase letter
              </li>
              <li className="text-[13px]">Contains at least one number</li>
              <li className="text-[13px]">
                Contains at least one symbol or special character
              </li>
            </ul>
          </div> */}
          <div className="flex justify-start items-start gap-3">
            <CustomCheckBox
              name="terms"
              id="terms"
              isChecked={() => setInputValues({ ...inputValues, terms: true })}
              isNotChecked={() =>
                setInputValues({ ...inputValues, terms: false })
              }
              checked={inputValues.terms}
            />
            <p className="text-[#282B30] text-sm text-left mb-12">
              I agree to TasteClan's
              <span className="text-primary font-semibold">
                {" "}
                Terms and Conditions.
              </span>
            </p>
          </div>
          <div className="w-full h-14 mb-10">
            <PrimaryButton
              handle={handleSubmit}
              isButtonDisabled={!!isFormValid}
              isButtonLoading={loadingEmailAuth || loadingGoogleAuth}
            >
              Sign Up
            </PrimaryButton>
          </div>
        </form>

        <p className="w-full font-semibold text-sm text-[#7B7B7B] flex justify-center items-center gap-4 mb-6">
          <span className="w-32 border border-[#D9D9D9]  inline-block "></span>
          OR
          <span className="w-32 border border-[#D9D9D9]  inline-block "></span>
        </p>
        <p className="text-center text-[#282B30] text-sm mb-7">Sign up with</p>
        <div className="flex justify-center items-center gap-10 mb-12">
          {/* google icon */}
          <span
            // onClick={gooogleSignIn}
            onClick={handleGoggleAuth}
            className="bg-[#282B30] w-10 h-10 rounded-full flex justify-center items-center cursor-pointer "
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.50289 6.4544C3.33273 6.9519 3.24624 7.47419 3.24695 8C3.24695 8.5872 3.35253 9.1496 3.54768 9.66879C3.79092 10.3177 4.17298 10.9057 4.66715 11.3915C5.16131 11.8773 5.75563 12.2492 6.40854 12.4812C7.06144 12.7133 7.75714 12.7998 8.44698 12.7348C9.13681 12.6697 9.8041 12.4547 10.4022 12.1048H10.403C11.3452 11.5523 12.0691 10.6929 12.4537 9.67039H8.17706V6.5056H15.8594C16.0581 7.55238 16.0461 8.62829 15.8242 9.67039C15.4396 11.4719 14.4452 13.0854 13.0088 14.2384C11.5901 15.3811 9.82262 16.0028 8.0011 16C6.62778 16.0008 5.2774 15.6477 4.08007 14.975C2.88274 14.3022 1.87882 13.3324 1.16502 12.1589C0.451218 10.9853 0.0516024 9.64775 0.00467147 8.27493C-0.0422595 6.90211 0.265077 5.54033 0.897069 4.3208C1.5713 3.01833 2.5906 1.92637 3.84352 1.16432C5.09644 0.402274 6.53473 -0.00051429 8.0011 4.92819e-07C9.94147 4.92819e-07 11.7211 0.6912 13.1064 1.8416L10.5149 3.9632C9.9079 3.58531 9.22349 3.34915 8.51261 3.27227C7.80174 3.19539 7.08268 3.27978 6.4089 3.51915C5.73512 3.75853 5.12396 4.14674 4.62084 4.65491C4.11773 5.16309 3.73561 5.77816 3.50289 6.4544Z"
                fill="#F9F9F9"
              />
            </svg>
          </span>
        </div>
        <p className="hidden text-center text-[#4A4D53] text-sm mt-10 md:block">
          I have an account{" "}
          <span
            onClick={() => {
              setIsLoginModalOpen(true);
              setIsRegisterModalOpen(false);
            }}
            className="text-primary font-semibold ml-2 cursor-pointer"
          >
            Sign in
          </span>
        </p>
        <p className="text-center text-sm mt-10 md:hidden">
          I have an account{" "}
          <span
            onClick={() => {
              setIsLoginModalOpen(true);
              setIsRegisterModalOpen(false);
            }}
            className="text-primary font-semibold ml-2 cursor-pointer"
          >
            Sign in
          </span>
        </p>
      </div>
    </>
  );
};

export default Register;
