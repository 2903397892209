import React, { useContext, useState } from "react";
import Navigation from "../../Layout/Navigation";
import { useParams, useLocation } from "react-router-dom";
import useMakeOrd from "./hooks/useMakeOrd";
import AuthContext from "../../contexts/AuthContext";
import ModalContext from "../../contexts/ModalContext";

const Checkout = () => {
  const { accessToken } = useContext(AuthContext);
  const url_location = useLocation();
  const params = new URLSearchParams(url_location?.search);
  const order_status_query = params.get("order_status");

  const [selectedTakeAwaySize, setSelectedTakeAwaySize] =
    useState("small_takeaway");

  const handleTakeawaySizeChange = (size) => {
    setSelectedTakeAwaySize(size);
  };

  const { setIsPreOrderOpen, setAlertProps } = useContext(ModalContext);

  const isPreorder = order_status_query !== "0";

  const { restaurantName } = useParams();

  //const restaurantWithTakeAwayPlates = ["La Spag"];
  const restaurantsThatCanChoseTakeAwayPlateSize = [
    "9ja Food",
    "New Restuarant",
  ];

  //const checkRestaurantTakeAwayPlates = () => restaurantWithTakeAwayPlates.includes(restaurantName);

  const checkIfRestaurantCanPickPlateSize = () =>
    restaurantsThatCanChoseTakeAwayPlateSize.includes(restaurantName);

  const cartItems = JSON.parse(sessionStorage.getItem("cart"));
  cartItems.map((item) => {
    return {
      ...(item.total = item.quantity * item.food_price),
      ...(item.restaurant_name = restaurantName),
    };
  });

  const takeAway = selectedTakeAwaySize === "big_takeaway" ? 600 : 350;



  //let food_total = 0

  const cartTotalPrice = () => {
    let total = 0;
    cartItems.forEach((item) => {
      //food_total = item.food_price *item.quantity
      total += item.food_price * item.quantity;
    });
    return total;
  };

  const payloadExtendTakeaway = {
    food_name: selectedTakeAwaySize || "Take Away",
    quantity: 1,
    restaurant_name: restaurantName,
    total: takeAway,
  };
  // vat charge
  //let vatCharge = (0.014) * (cartTotalPrice());
  //let vatCharge = 0;

  let sub_total = parseFloat(cartTotalPrice()); // total price of items
  let order_details = [...cartItems, { ...payloadExtendTakeaway }]; // items being ordered with the price, restaurants
  //that they are ordered from, and quantity that

  // checked out and ordered
  //let delivery_fee = deliveryFee; // delivery fee

  const totalPrice = sub_total + takeAway;



  const serviceFee = (0.1 * totalPrice).toFixed(2);

  const originalRealTotal = totalPrice + parseFloat(serviceFee);

  const payload = {
    order_details,
    sub_total: originalRealTotal,
  };
  
  // custom hook to make order checkout
  const { mutate: make_order, isLoading } = useMakeOrd(payload, accessToken);

  //const { accessToken } = React.useContext(AuthContext)
  const handleMakeOrderPayment = () => {
    if (totalPrice === 0) {
      setAlertProps({
        type: "fail",
        title: "Cannot order these items.",
        subtitle: null,
      });
      setTimeout(() => {
        setAlertProps({
          type: "",
          title: "",
          subtitle: null,
        });
      }, 5000);
      return;
    }

    make_order();

    sessionStorage.setItem("amountToBePaid", originalRealTotal);
  };

  return (
    <React.Fragment>
      <Navigation />
      <main className="min-h-screen w-full relative grid grid-cols-1 place-content-center h-full">
        <div className="h-fit justify-center flex-none md:flex flex-col items-center w-full">
          <section className="flex flex-col md:w-5/6 items-start justify-center overflow-auto mt-20 h-3/5 px-5 md:px-20">
            <div className="leading-8 flex flex-col items-start py-10">
              <p className="md:text-[36px] text-[#A8ABB2] font-bold ">
                Order Details
              </p>
              <p className="md:text-[12px] text-[#7B7B7B] font-bold ">
                {restaurantName}
              </p>
            </div>
            {cartItems.map((cartItem) => {
              return (
                <section
                  key={cartItem.id}
                  className="flex justify-between w-full my-6"
                >
                  <div className="items-start flex flex-col">
                    <p className="text-lg md:text-xl text-dark font-semibold text-left break-all">
                      {cartItem.food_name}
                    </p>
                    <p className="text-dark">Quantity {cartItem.quantity}</p>
                  </div>

                  <div className="invisible md:visible text-lg md:text-xl text-dark font-semibold text-left break-all">
                    <p>{cartItem.food_price}</p>
                  </div>
                </section>
              );
            })}

            {checkIfRestaurantCanPickPlateSize() ? (
              <>
                <div className="my-4 border-1 border w-full border-gray.400"></div>
                <p className="text-lg md:text-xl text-dark font-semibold text-left break-all mb-2">
                  Select the size of your takeaway
                </p>
                <div className="mb-4">
                  {/* <p className="align-start">Pick Takeaway Size</p> */}
                  <label className="inline-flex items-center cursor-pointer ">
                    <input
                      type="radio"
                      className="radio-button"
                      checked={selectedTakeAwaySize === "big_takeaway"}
                      onChange={() => handleTakeawaySizeChange("big_takeaway")}
                    />
                    <span className="ml-2">Big Size</span>
                  </label>
                  <label className="inline-flex items-center cursor-pointer ml-6">
                    <input
                      type="radio"
                      className="radio-button w-4 h-4"
                      checked={selectedTakeAwaySize === "small_takeaway"}
                      onChange={() =>
                        handleTakeawaySizeChange("small_takeaway")
                      }
                    />
                    <span className="ml-2">Small Size</span>
                  </label>
                </div>
              </>
            ) : null}
          </section>

          {/* second section, colored section */}
          <div className="w-full ">
            <fieldset className="bg-[#FECCBF] flex items-center justify-center w-full h-2/6 md:h-2/5 px-0 md:px-20 py-10">
              <section className="w-full md:w-5/6 px-5 md:px-0 mb-2">
                <div className="flex w-full justify-between my-3 text-dark">
                  <p className="font-medium  text-lg md:text-xl leading-[20px]">
                    Subtotal
                  </p>
                  <p className="font-medium md:text-xl">{cartTotalPrice()}</p>
                </div>
                {/* delivery */}
                {/* <div className="flex w-full justify-between my-3 text-dark">
                <p className=" font-medium text-lg md:text-xl leading-[20px]">
                  Delivery
                </p>
                <p className="font-medium md:text-xl">0</p>
              </div> */}

                {/* <div className="flex w-full justify-between my-3 text-dark">
                  <p className="font-medium  text-lg md:text-xl leading-[20px]">
                    V.A.T
                  </p>
                  <p className="font-medium md:text-xl">{vatCharge}</p>
                </div> */}
              

                <div className="flex w-full justify-between my-3 text-dark">
                <p className="font-medium  text-lg md:text-xl leading-[20px]">
                    Take away Plate
                  </p>
                  <p className="font-medium md:text-xl">{takeAway}</p>
                </div>

                <div className="flex w-full bg red-500 justify-between my-3 text-dark">
                  <div className="leading-[20px] flex items-center space-x-3">
                  <p className="font-medium  text-lg md:text-xl ">
                   Service Fee
                  </p>
                  <small>(10% of the total price)</small>
                  </div>
                  <p className="font-medium md:text-xl">{serviceFee}</p>
                </div>

                {/* promocode */}
                {/* <div className="flex w-full justify-between my-3 text-dark">
                <p className=" font-medium text-lg md:text-xl leading-[20px]">
                  Promo Code
                </p>
                <div>
                  <input
                    type="text"
                    placeholder="Enter code"
                    className="bg-transparent border-b placeholder:text-gray-700 border-gray-900 outline-none text-dark text-lg w-32 px-1"
                  />
                </div>
              </div> */}
                <hr className="border-gray-700 my-10 invisible md:visible" />

                <section className="flex w-full justify-between my-5">
                  <p className="font-semibold text-lg md:text-3xl leading-[20px]">
                    Total
                  </p>
                  <p className="font-bold md:text-3xl">
                    <span className="mr-2">NGN</span>
                    {originalRealTotal?.toLocaleString()}
                  </p>
                </section>
                <button
                  className="w-full invisible md:visible h-[56px] md:w-[500px] md:h-[72px] bg-[#FE724E] md:rounded-[1000px] text-white font-bold"
                  onClick={(e) => {
                    e.preventDefault();
                    if (isPreorder) {
                      setIsPreOrderOpen(true);
                      setTimeout(() => {
                        handleMakeOrderPayment();
                      }, 2000);
                    } else {
                      handleMakeOrderPayment();
                    }
                  }}
                >
                  {isLoading ? (
                    <svg
                      className="mx-auto animate-spin"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 1.66671C8.35186 1.66671 6.7407 2.15545 5.37029 3.07113C3.99988 3.9868 2.93177 5.28829 2.30104 6.81101C1.67031 8.33373 1.50529 10.0093 1.82683 11.6258C2.14837 13.2423 2.94204 14.7272 4.10748 15.8926C5.27292 17.058 6.75778 17.8517 8.37429 18.1733C9.99079 18.4948 11.6664 18.3298 13.1891 17.699C14.7118 17.0683 16.0133 16.0002 16.929 14.6298C17.8446 13.2594 18.3334 11.6482 18.3334 10"
                        stroke="#F9F9F9"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  ) : isPreorder ? (
                    `Preorder: NGN ${originalRealTotal?.toLocaleString()}`
                  ) : (
                    `Checkout: NGN ${originalRealTotal?.toLocaleString()}`
                  )}
                </button>
              </section>
            </fieldset>
            <button
              disabled={isLoading ? true : false}
              className="md:hidden  visible w-full bg-primary text-white py-4 font-semibold"
              onClick={(e) => {
                e.preventDefault();
                if (isPreorder) {
                  setIsPreOrderOpen(true);
                  setTimeout(() => {
                    handleMakeOrderPayment();
                  }, 2000);
                }
                handleMakeOrderPayment();
              }}
            >
              {isLoading ? (
                <svg
                  className="mx-auto animate-spin"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 1.66671C8.35186 1.66671 6.7407 2.15545 5.37029 3.07113C3.99988 3.9868 2.93177 5.28829 2.30104 6.81101C1.67031 8.33373 1.50529 10.0093 1.82683 11.6258C2.14837 13.2423 2.94204 14.7272 4.10748 15.8926C5.27292 17.058 6.75778 17.8517 8.37429 18.1733C9.99079 18.4948 11.6664 18.3298 13.1891 17.699C14.7118 17.0683 16.0133 16.0002 16.929 14.6298C17.8446 13.2594 18.3334 11.6482 18.3334 10"
                    stroke="#F9F9F9"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : isPreorder ? (
                `Preorder: NGN ${originalRealTotal?.toLocaleString()}`
              ) : (
                `Checkout: NGN ${originalRealTotal?.toLocaleString()}`
              )}
            </button>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default Checkout;
