import { useState, useContext } from 'react';
import axios from 'axios';
import ModalContext from '../../../contexts/ModalContext';

const { REACT_APP_CLOUDINARY_NAME } = process.env;

const useCloudinaryUpload = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [imageUrl, setImageUrl] = useState('');

    const { setAlertProps } = useContext(ModalContext);

    const uploadImage = async (file) => {
        setLoading(true);
        setError(null);

        const formData = new FormData();
        formData.append('file', file);
        formData.append('upload_preset', 'Tasteclan_Frontend');

        try {
            const response = await axios.post(
                `https://api.cloudinary.com/v1_1/${REACT_APP_CLOUDINARY_NAME}/image/upload`,
                formData
            );

            setImageUrl(response?.data?.url);

            setAlertProps({
                type: "success",
                title: "Success",
                subtitle: "File Uploaded",
            });
            setTimeout(() => {
                setAlertProps({
                    type: "",
                    title: "",
                    subtitle: null,
                });
            }, 3000);
        } catch (err) {
            // console.log("error")
            setError(err);
            setAlertProps({
                type: "fail",
                title: "Error",
                subtitle: err?.response?.data?.error?.message,
            });
            setTimeout(() => {
                setAlertProps({
                    type: "",
                    title: "",
                    subtitle: null,
                });
            }, 3000);
        } finally {
            setLoading(false);
        }
    };

    return {
        uploadImage,
        loading,
        error,
        imageUrl,
    };
};

export default useCloudinaryUpload;
