import React, { useContext, useState } from "react";
import PrimaryButton from "../components/form/PrimaryButton";
import Navigation from "../Layout/Navigation";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import AuthContext from "../contexts/AuthContext";
import ModalContext from "../contexts/ModalContext";
import CustomCheckBox from "../components/form/CustomAdminCheckbox";
import { useNavigate } from "react-router-dom";

const url = `${process.env.REACT_APP_BASE_URI}/checkout/pending_orders`;
const verifyUrl = `${process.env.REACT_APP_BASE_URI}/checkout/verify_and_complete`;
const usersUrl = `${process.env.REACT_APP_BASE_URI}/users/profile`;

const AdminPage = () => {
  const { accessToken } = useContext(AuthContext);
  const { setAlertProps } = useContext(ModalContext);

  const navigate = useNavigate();
  const fetchCurrentUser = () => axios.get(usersUrl, {
    headers: { Authorization: `Bearer ${accessToken}` }
  })

  // check and fetch if admin or user
  const { isLoading: fetchingCurrentUser } = useQuery(
    ['current-user'],
    fetchCurrentUser,
    {
      onSuccess: (res) => {
        // console.log(res.data?.user_data, 'res')
        if (res.data?.user_data?.roles === 'user') {
          navigate('/')
        }
      },
      onError: (error) => {}
    }
  )


  const amountForTC = (value) => (5 * 0.01 * parseInt(value)).toFixed(2);

  const [allOrderIds, setAllOrderIds] = useState([]);

  const pendingOrders = () =>
    axios.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

  const { data: getPendingOrders, isLoading: isFetchingOrders } = useQuery(
    ["pending order"],
    pendingOrders,
    {
      onSuccess: (res) => {
        // setOrderId(res.data?.data[0]?._id)
      },
      onError: (error) => {
        setAlertProps({
          type: "fail",
          title: "Error",
          subtitle: error?.response?.data?.message,
        });
        setTimeout(() => {
          setAlertProps({
            type: "",
            title: "",
            subtitle: null,
          });
        }, 5000);
      },
    }
  );

  const { mutate: verifyComplete, isLoading: isVerifyingOrder } = useMutation(
    () => {
      const payload = {
        order_id: allOrderIds,
      };

      return axios.post(verifyUrl, payload, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    },
    {
      onSuccess: (response) => {
        setAlertProps({
          type: "success",
          title: "Success",
          subtitle: response?.data?.message,
        });
        setTimeout(() => {
          setAlertProps({
            type: "",
            title: "",
            subtitle: null,
          });
        }, 5000);

        setAllOrderIds([]);
      },
      onError: (error) => {
        setAlertProps({
          type: "fail",
          title: "Error",
          subtitle: error?.response?.data?.message,
        });
        setTimeout(() => {
          setAlertProps({
            type: "",
            title: "",
            subtitle: null,
          });
        }, 5000);
      },
    }
  );

  const handleCheckboxChange = (food, isChecked) => {
    if (isChecked) {
      setAllOrderIds((prevIds) => [...prevIds, food?._id]);
    } else {
      setAllOrderIds((prevIds) => prevIds.filter((id) => id !== food?._id));
    }
  };

  const handleVerifyOrder = () => {
    if (allOrderIds.length === 0) return;
    verifyComplete();
  };

  if (fetchingCurrentUser) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-opacity-50">
      <div className="flex flex-col items-center">
        <svg
        className="animate-spin h-10 w-10 text-primary mb-4"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
        ></path>
        </svg>
        <p className="text-lg text-primary">Loading...</p>
      </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen w-full pb-0 md:pb-10 flex flex-col text-dark">
      <Navigation />
      <div className="px-6 md:px-24 w-full">
        <div className="w-full mt-20 md:mt-28 space-y-12 md:space-y-28 rounded-lg">
          <p className="text-start text-[18px] mb-4 text-[#A8ABB2] md:text-[40px]  font-semibold leading-[25px]">
            Admin Page
          </p>

          <div className="">
            <h2 className="text-2xl font-semibold mb-8 text-start">
              Order Confirmation Status
            </h2>
            <div className="flex flex-col space-y-2">
              <label className="flex items-center space-x-2">
                <input type="radio" name="status" className="radio-button" />
                <div className="w-[80%] md:w-fit">
                  <p className="text-[14px] md:text-[16px] text-start">
                    I have seen the alert, send confirmation mail to user.
                  </p>
                </div>
              </label>
              <label className="flex items-center space-x-2">
                <input type="radio" name="status" className="radio-button" />
                <div className="w-[80%] md:w-fit">
                  <p className="text-[14px] md:text-[16px] text-start">
                    I have not seen the alert, send inquiry mail to user.
                  </p>
                </div>
              </label>
            </div>
          </div>

          <div className="items-start">
            <h2 className="text-2xl font-semibold mb-8 text-start">
              Payment Breakdown
            </h2>
            <div className="overflow-x-auto rounded-t-[24px]">
              {isFetchingOrders ? (
                <>Loading...</>
              ) : (
                <table className="min-w-full p-10 rounded-t-[24px]   text-dark border rounded-lg">
                  <thead className="bg-primary rounded-t-[24px] text-white">
                    <tr>
                      <th></th>
                      <th className="py-8 text-nowrap px-6 font-normal text-left">
                        Food ID
                      </th>
                      <th className="py-8 text-nowrap px-6 font-normal">
                        Money Sent(&#x20A6;)
                      </th>
                      <th className="py-8 px-6 text-nowrap font-normal">
                        Amount for TasteClan(&#x20A6;)
                      </th>

                      <th className="py-8 px-6 text-nowrap font-normal">
                        Restaurant Name
                      </th>
                      <th className="py-8 px-6 text-nowrap font-normal">
                        Account Bank
                      </th>
                      <th className="py-8 px-6 text-nowrap font-normal">
                        Account Number
                      </th>

                      <th className="py-8 px-6 text-nowrap font-normal text-right">
                        Amount to send to Vendor(&#x20A6;)
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-[#FECCBF] text-darkbrown-500 px-20 ">
                    {getPendingOrders?.data?.data?.map((food, index) => (
                      <tr key={index} className="px-10">
                        <td className="px-6 py-8">
                          <CustomCheckBox
                            name={`checkbox-${index}`}
                            checked={allOrderIds.includes(food?._id)}
                            isChecked={() => handleCheckboxChange(food, true)}
                            isNotChecked={() =>
                              handleCheckboxChange(food, false)
                            }
                          />
                        </td>
                        <td className="py-8 px-6 border-t border-gray-300 text-left">
                          {food?.food_id}
                        </td>
                        <td className="py-8 px-6 border-t border-gray-300">
                          {food?.Sub_total}
                        </td>
                        <td className="py-8 px-6 border-t border-gray-300">
                          {amountForTC(food?.Sub_total)}
                        </td>

                        <td className="py-8 px-6 border-t border-gray-300">
                          {food?.account_details?.restaurant_name}
                        </td>
                        <td className="py-8 px-6 border-t border-gray-300">
                          {food?.account_details?.bank}
                        </td>
                        <td className="py-8 px-6 border-t border-gray-300">
                          {food?.account_details?.account_number}
                        </td>

                        <td className="py-8 px-6 border-t border-gray-300 text-right">
                          {parseInt(food?.Sub_total) -
                            amountForTC(food?.Sub_total)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>

          {/* <div className="items-start">
                        <h2 className="text-2xl text-start font-semibold mb-8">Vendor Account Details</h2>
                        <div className="items-start flex flex-col p-6 md:p-16 rounded-[21px] bg-[#FECCBF] w-full md:w-[400px] lg:w-[550px]">
                            <div className="mb-2 flex items-center w-full justify-between">
                                <span className="text-[14px] md:text-[20px]">Account number: </span>
                                <span className='font-semibold text-[16px] md:text-[24px]'>0231234204</span>
                            </div>
                            <div className="mb-2 flex items-center w-full justify-between">
                                <span className="text-[14px] md:text-[20px]">Bank name: </span>
                                <span className='font-semibold text-[16px] md:text-[24px]'>UBA</span>
                            </div>
                            <div className='flex items-center w-full space-x-5 justify-between'>
                                <p className="text-[14px] md:text-[20px]">Account name: </p>
                                <p className=' font-semibold text-[16px] md:text-[24px]'>Fintas Kitchen</p>
                            </div>
                        </div>
                    </div> */}

          <div className="h-[72px] w-full hidden md:grid place-items-center">
            <PrimaryButton
              isButtonLoading={isVerifyingOrder}
              handle={handleVerifyOrder}
            >
              Order Complete
            </PrimaryButton>
          </div>
        </div>
      </div>
      <div className="w-full md:hidden mt-20 grid place-items-center">
        <button
          disabled={isVerifyingOrder}
          onClick={handleVerifyOrder}
          className="h-[56px] w-full bg-primary text-white py-4 font-semibold"
        >
          {isVerifyingOrder ? "Verfying" : "Order Complete"}
        </button>
      </div>
    </div>
  );
};

export default AdminPage;
