import { React } from "react";
import Counter from "../components/form/Counter";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import CustomCheckBox from "../components/form/CustomCheckBox";
import Navigation from "../Layout/Navigation";
import { useNavigate, useLocation } from "react-router-dom";
import { FaStar } from 'react-icons/fa'

const Foodlist = () => {
  const [foodItems, setFoodItems] = useState(
    JSON.parse(sessionStorage.getItem("foodItems"))
  );

  //  implementing example for preorder feature balh blah on foodlist
  const restaurantData = JSON.parse(sessionStorage.getItem("restaurantData"))
  const pre_order = restaurantData?.preorder ?? false
  // ---end of implementation for this file

  const [searchFood, setSearchFood] = useState("");
  const [status, setStatus] = useState("");
  const cart = foodItems.filter((item) => {
    return item.active === true;
  });

  // url parameters
  const { restaurantName, id } = useParams();
  //console.log(restaurantName, id, 'restaurantName, id')

  let navigate = useNavigate();
  const location = useLocation();
 // console.log(location, 'ieinfunefnfr')

  useEffect(() => {
    let dayName = new Date();
    //console.log(dayName)
    switch (dayName.getDay()) {
      case 0:
        location.state.sunday === true
          ? setStatus("Online")
          : setStatus("Online");
        break;
      case 1:
        location.state.monday === true
          ? setStatus("Online")
          : setStatus("Offline");
        break;
      case 2:
        location.state.tuesday === true
          ? setStatus("Online")
          : setStatus("Offline");
        break;
      case 3:
        location.state.wednesday === true
          ? setStatus("Online")
          : setStatus("Offline");
        break;
      case 4:
        location.state.thursday === true
          ? setStatus("Online")
          : setStatus("Offline");
        break;
      case 5:
        location.state.friday === true
          ? setStatus("Online")
          : setStatus("Offline");
        break;
      case 6:
        location.state.saturday === true
          ? setStatus("Online")
          : setStatus("Offline");
        break;
      default:
    }
  }, [location]);

  const addToCart = (food) => {
    const newItems = foodItems.map((item) =>
      food._id === item._id ? { ...item, active: true } : item
    );
    setFoodItems(newItems);
    sessionStorage.setItem("foodItems", JSON.stringify(newItems));
  };
  const removeFromcart = (foodID) => {
    const newItems = foodItems.map((item) =>
      foodID === item._id ? { ...item, active: false } : item
    );
    setFoodItems(newItems);
    sessionStorage.setItem("foodItems", JSON.stringify(newItems));
  };

  const increase = (food) => {
    const newItems = foodItems.map((item) =>
      food._id === item._id
        ? { ...item, quantity: item.quantity + (item.quantity >= 1 ? 1 : 0) }
        : item
    );
    setFoodItems(newItems);
    sessionStorage.setItem("foodItems", JSON.stringify(newItems));
  };
  const decrease = (food) => {
    const newItems = foodItems.map((item) =>
      food._id === item._id
        ? { ...item, quantity: item.quantity - (item.quantity > 1 ? 1 : 0) }
        : item
    );
    setFoodItems(newItems);
    sessionStorage.setItem("foodItems", JSON.stringify(newItems));
  };

  const foodTotalPrice = (food) => {
    let total = food.food_price * food.quantity;

    return total;
  };

  const cartTotalPrice = () => {
    let total = 0;
    cart.forEach((item) => {
      total += item.food_price * item.quantity;
    });
    return total;
  };

  const foodFilter = foodItems.filter((food) => {
    if (searchFood === "") {
      return food;
    } else {
      return food?.food_name.toLowerCase().includes(searchFood.toLowerCase());
    }
  });

  const nextPage = () => {
    sessionStorage.setItem("cart", JSON.stringify(cart));
    // refactored the link/navigation here 
    navigate(`/checkout/${restaurantName}?order_status=${pre_order ? "1" : "0"}`);
  };

  return (
    <>
      <Navigation />
      <div className="pt-24 min-h-screen ">
        <div className="sm:w-5/6 w-full mx-auto mb-40 md:mb-0  px-10  ">
          <div className="flex items-center md:flex-row flex-col gap-6 justify-between mb-20 mt-8">
            <div className="w-full mx-auto">
              <h2 className="font-bold text-3xl text-left ">
                {restaurantName}
              </h2>
              <h4 className="text-lg mt-2 text-[#7B7B7B] text-left">
                Feast for Nobles
              </h4>
              <div className='flex items-center justify-start gap-2 mt-4'>
                <FaStar className='text-[#FE724E]' size={25} />
                <p className='text-[#A8ABB2]'>4.3(17)</p>
                <div className='w-2 h-2 rounded-full bg-[#A8ABB2]'></div>
                <h3 className='text-[#FE724E] font-bold text-xl underline cursor-pointer'
                  onClick={() => {
                    navigate(`/feedback/${restaurantName}`, {
                      state: {
                        restaurantID: id
                      }
                    });
                  }}>Reviews</h3>
              </div>
            </div>
            <div>
              <input
                type="text"
                placeholder="🔍Search Food..."
                onChange={(e) => setSearchFood(e.target.value)}
                className="bg-transparent outline-none border-b-2 border-current pb-2 text-sm"
              />
            </div>
          </div>
          <div className="text-left mb-20">
            <h4 className="text-lg font-bold text-[#7B7B7B]">
              Availability:{" "}
              <span
                className={
                  status === "Online"
                    ? "bg-[#F4FFF7] text-[#000000] text-sm font-bold px-6 py-1 ml-4 rounded-xl border border-[#04554A]"
                    : "bg-[#FFF2EE] text-[#000000] text-sm font-bold px-6 py-1 ml-4 rounded-xl border border-[#FF2323]"
                }
              >
                {status}
              </span>{" "}
            </h4>


            <div>
              {
                pre_order ? (<p>Items will be delivered when the restaurant closes</p>) : (
                  <p className="text-[#7B7B7B] mt-4 text-sm">
                    {status === "Online"
                      ? "Order now, and get within 30 minutes "
                      : "You can’t place order now, we are closed at the moment"}
                  </p>
                )
              }
            </div>
          </div>
          {foodFilter.map((food) => {
            return (
              <div
                key={food._id}
                className=" py-8 h-auto text-left w-full flex justify-between items-center  rounded-2xl border px-6 sm:px-10 border-[#FE724E] mb-12  "
              >
                <div className="flex items-center w-6/12">
                  <CustomCheckBox
                    isChecked={() => addToCart(food)}
                    isNotChecked={() => removeFromcart(food._id)}
                    checked={food.active}
                  />
                  <h3 className="ml-3 text-xs sm:text-xl font-semibold break-all">
                    {food.food_name}
                  </h3>
                </div>
                <div className="flex items-center w-5/12 justify-end">
                  <Counter
                    id={food._id}
                    width={16}
                    height={16}
                    counter={food.quantity}
                    increase={() => increase(food)}
                    decrease={() => decrease(food)}
                  />
                  <p className="ml-2 sm:ml-5 text-xs sm:text-xl">
                    {" "}
                    {foodTotalPrice(food)}
                  </p>
                </div>
              </div>
            );
          })}
        </div>

        {foodItems && (
          <div className='fixed md:relative bottom-0 left-0 right-0 '>
          <div className="sm:w-5/6 w-full mx-auto">
            <div className=" mt-20  sm:flex  sm:justify-between sm:px-10">
              <div className="h-24 w-full ">
                <button
                  disabled={
                    cart.length === 0
                      ? true
                      : status === "Offline"
                        ? true
                        : false
                  }
                  className={`bg-primary hover:bg-[#BE391C] w-full h-full sm:rounded-full disabled:bg-primary/50`}
                  onClick={nextPage}
                >
                  <div className="flex flex-col">
                    <span className="text-2xl text-white m-auto font-bold">
                      {" "}
                      Add to cart
                    </span>
                    <span className="font-medium text-white text-lg">
                      NGN: {cartTotalPrice()}
                    </span>
                  </div>
                </button>
              </div>
            </div>
          </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Foodlist;
