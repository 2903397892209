import React from "react";

const TextField = ({
  type,
  handleInputChange,
  value,
  name,
  placeholder,
  disabled,
  icon,
  className,
  readOnly
}) => {
  return (
    <label className=" h-full w-full relative">
      <span className="absolute top-1/2 left-5 -translate-y-1/2 ">{icon}</span>
      <input
        name={name}
        type={type}
        readOnly={readOnly}
        disabled={disabled}
        value={value}
        onChange={handleInputChange}
        placeholder={placeholder}
        className={` outline-none  rounded-3xl items-start h-full w-full bg-[#E8E8E8] focus:border-b-2 focus:outline-primary placeholder:text-sm  ${
          icon ? "pl-12 pr-5 " : "px-5"
        } ${className}`}
      />
    </label>
  );
};

export default TextField;
