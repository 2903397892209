import React, { useContext, useState } from "react";
import Navigation from "../../Layout/Navigation";
import TextField from "../../components/form/TextField";
//import PrimaryButton from '../../components/form/PrimaryButton'
import useCompleteOrd from "./hooks/useCompleteOrd";
import AuthContext from "../../contexts/AuthContext";
import ModalContext from "../../contexts/ModalContext";
// import AuthContext from '../../contexts/AuthContext'
const Payment = () => {
  const { accessToken } = useContext(AuthContext);
  const { setAlertProps } = useContext(ModalContext);

  const userData  = JSON.parse(sessionStorage.getItem('user_data'));
 
  const [inputValues, setInputValues] = useState({
    fullname: "",
    landmark: "",
    email: userData?.email,
    delivery_address: "",
    phoneNumber: "",
    order_note: "",
  });

  const nearest_locations = [
    {
      name: "Futascoops Hotel",
      id: "1"
    },
    {
      name: "New Hostel",
      id: "2"
    },
    {
      name: "Akindeko",
      id: "3"
    },
    {
      name: "Adeniyi/Jadesola Hostel",
      id: "4"
    },
    {
      name: "Abiola/Jibowu Hostel",
      id: "5"
    },
    {
      name: "Rescue Area",
      id: "6"
    },
    {
      name: "IGEM area",
      id: "7"
    },
    {
      name: "Apatapiti",
      id: "8"
    },
    {
      name: "Hill Crest",
      id: "9"
    },
    {
      name: "Gaga",
      id: "10"
    },
    {
      name: "Glofes",
      id: "11"
    },
    {
      name: "Awero",
      id: "12"
    },
    {
      name: "Baydok",
      id: "13"
    },
    {
      name: "Wesco",
      id: "14"
    },
    {
      name: "Deeper Life camp ground road",
      id: "15"
    },
    {
      name: "Akad",
      id: "16"
    },
    {
      name: "Ibule",
      id: "17"
    },
    {
      name: "Alice Mosunmola",
      id: "18"
    },
    {
      name: "Kings and Queen",
      id: "19"
    },
    {
      name: "Ipinsha",
      id: "20"
    },
    {
      name: "Roadblock",
      id: "21"
    },
    {
      name: "Alejolowo",
      id: "22"
    },
    {
      name: "Nimok",
      id: "23"
    },
    {
      name: "New Castle",
      id: "24"
    },
    {
      name: "Stateline",
      id: "25"
    },
    {
      name: "Blue Roof",
      id: "26"
    },
    {
      name: "RCF Junction",
      id: "27"
    },
    {
      name: "Embassy Lodge Area",
      id: "28"
    },
    {
      name: "Rotsam",
      id: "29"
    },
    {
      name: "Aba Area",
      id: "30"
    },
    {
      name: "Bola International School",
      id: "31"
    },
    {
      name: "Others",
      id: "32"
    }
  ];
  const isFormField = inputValues.fullname === "" || inputValues.landmark === "" || inputValues.delivery_address.trim() === ''

  // implementing custom hook usage to proceed to payment with flutter wave
  // parameters to be passed to the custom hook which are { order_id, country, address, state, city, zip code, phone number, order note and user's id}
  let order_id = sessionStorage.getItem("order_id");
  let fullname = inputValues.fullname;
  let address = inputValues.delivery_address;
  let landmark = inputValues.landmark;
  let phone_number = inputValues.phoneNumber;
  let order_note = inputValues.order_note;
  let email = inputValues.email

  const payload = {
    order_id,
    fullname,
    email,
    address,
    landmark,
    phone_number,
    order_note,
  };

  // handle function from the custom hook to proceed to payment with flutter wave
  const { mutate: complete_order, isLoading } = useCompleteOrd(
    payload,
    accessToken
  );
  //const { accessToken } = React.useContext(AuthContext);

  // handle function to handle complete order
  const handleCompleteClick = () => {
    if (isFormField) {
      setAlertProps({
        type: "fail",
        title: "Fill Missing form field",
        subtitle: "",
      });
      setTimeout(() => {
        setAlertProps({
          type: "",
          title: "",
          subtitle: null,
        });
      }, 5000);
      return;
    };

    if (landmark.toLowerCase() === "others" && (address.trim() === "")) {
      setAlertProps({
        type: "fail",
        title: "Enter address",
        subtitle: "",
      });
      setTimeout(() => {
        setAlertProps({
          type: "",
          title: "",
          subtitle: null,
        });
      }, 5000);
      return;
    }
    sessionStorage.setItem("delivery_details", JSON.stringify(payload))
    complete_order();
  };

  const handleInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  return (
    <React.Fragment>
      <Navigation />
      <div className="h-screen w-full grid grid-cols-1 place-items-center">
        {/* section for textfield for the full name */}
        <main className="w-5/6 md:w-3/5 flex flex-col items-center">
          <p className=" text-[18px] mb-4 text-[#A8ABB2] md:text-[40px]  font-semibold leading-[25px]">
            Delivery Details
          </p>

          <p className="font-[600] text-center text-lg my-4 text-[#282B30]">
            Kindly note that, delivery fee will be paid on delivery
          </p>
          <section className="w-full">
            <TextField
              className="py-4 mb-4"
              type="text"
              value={inputValues.fullname}
              name="fullname"
              placeholder="Full Name"
              icon={""}
              handleInputChange={handleInputChange}
            />
          </section>

          <section className="w-full">
            <TextField
              className="py-4 mb-4"
              type="email"
              value={userData?.email}
              readOnly={true}
              name="email"
              placeholder="Email Address"
              icon={""}
              handleInputChange={handleInputChange}
            />
          </section>

          <section className="w-full mb-4  flex-none md:flex justify-between">
            <div className="w-full mb-4 md:mb-0">
              {/* input field for deilvery address */}
              <TextField
                className="py-4"
                type="text"
                value={inputValues.delivery_address}
                name="delivery_address"
                placeholder="Delivery Address"
                icon={""}
                handleInputChange={handleInputChange}
              />
            </div>

            <div className="w-full pl-0 md:pl-8 md:mb-0">
              {/* input field  for area landmark*/}
              {/* <TextField
                className={`py-4`}
                type="text"
                value={inputValues.landmark}
                name="landmark"
                placeholder="Nearest Popular Place"
                icon={""}
                handleInputChange={handleInputChange}
              /> */}

              <div className="outline-none w-full px-2.5 items-start rounded-3xl bg-[#E8E8E8] h-full focus:border-b-2 focus:outline-primary ">
                <select value={inputValues.landmark} name={'landmark'} className='outline-none text-[#A8ABB2]  w-full px-2.5 items-start py-4 rounded-3xl bg-[#E8E8E8] h-full focus:border-b-2 focus:outline-primay placeholder:text-sm' onChange={handleInputChange}>
                  <option className=''>Choose Area</option>
                  {
                    nearest_locations.map((area, index) => (
                      <option className="text-dark" key={index}>
                        {area.name}
                      </option>
                    ))
                  }
                </select>
              </div>


            </div>
          </section>

          <section className="w-full flex-none mb-4 md:flex justify-between">
            <div className="w-full mb-4 md:mb-0">
              {/* input field for deilvery address */}
              <TextField
                className={`py-4`}
                type="tel"
                value={inputValues.phoneNumber}
                name="phoneNumber"
                placeholder="Phone Number"
                icon={""}
                handleInputChange={handleInputChange}
              />
            </div>

            <div className="w-full pl-0 md:pl-8 mb-4 md:mb-0">
              {/* input field  for Order Note*/}
              <TextField
                className={`py-4`}
                type="text"
                value={inputValues.order_note}
                name="order_note"
                placeholder="Order Note(Optional)"
                icon={""}
                handleInputChange={handleInputChange}
              />
            </div>
          </section>

          {/* button to proceed to payment*/}
          <section className="w-4/5">
            {/* <PrimaryButton
                    className=''
                    handle={''} isButtonLoading={false} isButtonDisabled={false}>Proceed To Payment</PrimaryButton> */}
            <button
              className="w-full invisible md:visible h-[56px] md:w-[500px] md:h-[72px] bg-[#FE724E] md:rounded-[1000px] text-white font-bold"
              onClick={(e) => {
                e.preventDefault();

                handleCompleteClick();
              }}
              disabled={isLoading ? true : false}
            >
              {isLoading ? (
                <svg
                  className="mx-auto animate-spin"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 1.66671C8.35186 1.66671 6.7407 2.15545 5.37029 3.07113C3.99988 3.9868 2.93177 5.28829 2.30104 6.81101C1.67031 8.33373 1.50529 10.0093 1.82683 11.6258C2.14837 13.2423 2.94204 14.7272 4.10748 15.8926C5.27292 17.058 6.75778 17.8517 8.37429 18.1733C9.99079 18.4948 11.6664 18.3298 13.1891 17.699C14.7118 17.0683 16.0133 16.0002 16.929 14.6298C17.8446 13.2594 18.3334 11.6482 18.3334 10"
                    stroke="#F9F9F9"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                "Proceed to Payment"
              )}
            </button>
          </section>
        </main>
        <button
          className="md:hidden  fixed bottom-0 visible w-full bg-primary text-white py-4 font-semibold"
          onClick={(e) => {
            e.preventDefault();

            handleCompleteClick();
          }}
        >
          {isLoading ? (
            <svg
              className="mx-auto animate-spin"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 1.66671C8.35186 1.66671 6.7407 2.15545 5.37029 3.07113C3.99988 3.9868 2.93177 5.28829 2.30104 6.81101C1.67031 8.33373 1.50529 10.0093 1.82683 11.6258C2.14837 13.2423 2.94204 14.7272 4.10748 15.8926C5.27292 17.058 6.75778 17.8517 8.37429 18.1733C9.99079 18.4948 11.6664 18.3298 13.1891 17.699C14.7118 17.0683 16.0133 16.0002 16.929 14.6298C17.8446 13.2594 18.3334 11.6482 18.3334 10"
                stroke="#F9F9F9"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            "Proceed to Payment"
          )}
        </button>
      </div>
    </React.Fragment>
  );
};

export default Payment;
