import React from "react";
import { NavLink } from "react-router-dom";
import arrowIcon from "../assets/arrowIcon.svg";
import { HashLink } from "react-router-hash-link";

export const HamburgerMenu = ({
  isHamburgerOpen,
  userLoggedIn,
  closeHamburger,
  signUpModal,
  logOut,
}) => {
  if (!isHamburgerOpen) {
    return null;
  }

  return (
    <div className=" lg:hidden block fixed top-0 h-screen w-full z-[48] backdrop-blur-[5px] backdrop-brightness-50 ">
      <div className="w-6/12 relative h-full py-8 pl-6 text-left bg-[#F2F2F2]">
        <div
          className="flex gap-4 text-primary cursor-pointer items-center"
          onClick={closeHamburger}
        >
          <div className="w-4">
            <img src={arrowIcon} className="w-full object-cover" alt="" />
          </div>
          <p>Back</p>
        </div>
        <div className="mt-8">
          <NavLink
            to="/"
            className={({ isActive }) => (isActive ? "link-active" : "link")}
            onClick={closeHamburger}
          >
            <li className=""> Home</li>
          </NavLink>
        </div>
        {/* {userLoggedIn && ( */}
        <div className="mt-6">
          <NavLink
            to="/restaurants"
            className={({ isActive }) => (isActive ? "link-active" : "link")}
            onClick={closeHamburger}
          >
            <li className=""> Restaurants</li>
          </NavLink>
        </div>
        {/* )} */}
        <div className="mt-6">
          <HashLink to="/#joinClan" onClick={closeHamburger} className="link">
            <li className=""> Join Clan</li>
          </HashLink>
        </div>
        <div className="mt-6">
          <HashLink to="/#contact" onClick={closeHamburger} className="link">
            <li className="">Contact</li>
          </HashLink>
        </div>
        {!userLoggedIn && (
          <button
            className="md:hidden py-3 px-8 text-white text-sm uppercase hover:opacity-80 rounded-full absolute bottom-28 bg-secondary block"
            onClick={signUpModal}
          >
            Sign Up
          </button>
        )}
        {userLoggedIn && (
          <p
            onClick={() => logOut()}
            className="text-primary font-medium absolute bottom-16 cursor-pointer"
          >
            Log Out
          </p>
        )}
      </div>
    </div>
  );
};
